<template>
  <div class="modal--small">
    <div class="modal-title">
      {{ data.label }} пользователя {{ data.nameUser }}?
    </div>
    <div class="btns-group days" v-if="data.type">
      <div class="modal-title mb-0 mr-10">На</div>
      <template v-for="day in numberDays">
        <button
          :class="['btn-white--border', { 'btn--green': chooseDays === day }]"
          @click="chooseDays = day"
          :key="day"
        >
          {{ day }}
        </button>
      </template>
      <div class="modal-title mb-0">дней</div>
    </div>
    <div class="btns-group">
      <button class="btn-white--border mr-20" @click="$emit('closeModal')">
        Отмена
      </button>
      <button class="btn--green" @click="data.callbackModal(chooseDays)">
        Да
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmUserChange',
  data() {
    return {
      numberDays: [5, 10, 15, 30],
      chooseDays: 5,
    };
  },
  props: {
    data: Object,
  },
};
</script>

<style lang="scss" scoped>
.days {
  margin-bottom: 20px;
  align-items: center;
  .mb-0 {
    margin-bottom: 0;
  }
  .mr-10 {
    margin-right: 10px;
  }
  button {
    width: 20px;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
