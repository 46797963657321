import { render, staticRenderFns } from "./ConfirmUserChange.vue?vue&type=template&id=14be8513&scoped=true&"
import script from "./ConfirmUserChange.vue?vue&type=script&lang=js&"
export * from "./ConfirmUserChange.vue?vue&type=script&lang=js&"
import style0 from "./ConfirmUserChange.vue?vue&type=style&index=0&id=14be8513&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14be8513",
  null
  
)

export default component.exports